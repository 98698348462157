@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&family=Graduate&family=K2D&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Signika:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&family=Graduate&family=K2D&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Secular+One&family=Signika:wght@300;400;500;600;700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&family=Bungee+Shade&family=Graduate&family=K2D&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Secular+One&family=Signika:wght@300;400;500;600;700&display=swap');


#groupImg{
  animation: borderColor infinite 1s;
}

#nav-item .active{
color:#DABFFF;
transition-duration:250ms;
}

@keyframes borderColor{
50% {border-color:#77DDEE;}
}



#ring{
  animation: spinner 15s linear infinite;
  position: absolute;
  top:0;
  left:0;
  
}  
#ring:hover {
  animation: spin 6s linear infinite;
  
} 


#stamp{
  animation: spinner 10s linear infinite;
}


@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  30% {
    transform: rotate(720deg);
  }
  60%{
    transform: rotate(1440deg);
  }
  90%{
    transform: rotate(2880deg);
  }
  100%{
    transform: rotate(5760deg);
  }
}

